<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pt-lg-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <!----------------------filters---------------------------->
          <v-layout wrap>
            <v-flex xs12 lg3 pb-2 class="nsbold text-left">
                    <span class="nsbold" style="font-size: 23px"
                      >Study Request</span
                    >
                  </v-flex>
            <v-flex xs12 sm3 pb-2>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    clearable
                    hide-details
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 pl-md-4 pb-2 pl-sm-2>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    hide-details
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 pl-lg-4 pb-2 pl-sm-2>
              <v-text-field
                v-model="keyword"
                dense
                label="Search : Ad.no/Name"
                outlined
                hide-details
                clearable
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 md6 lg3 pl-md-4 pt-md-6>
             <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select>
          </v-flex> -->
            <!--------------------------list------------------------>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="outpass">
              <v-layout wrap pb-md-2 class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="outpass.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Class</th>
                          <th class="text-left">From</th>
                          <th class="text-left">To</th>
                          <th class="text-left">Purpose</th>
                          <th class="text-left">Status</th>
                          <!-- <th class="text-left">Approve</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in outpass" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ item.admissionNo }}
                          </td>
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item.studentid._id"
                            >
                            {{ item.studentid.name }}
                            </router-link>
                            </td>
                          <td>
                            <span v-if="item.studentid">
                              {{ item.studentid.Class }} :
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.fromTime">
                              {{ formatDate(item.fromTime) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.toTime">
                              {{ formatDate(item.toTime) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.reason">
                              {{ item.reason }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.permissionStatus == 'Rejected'">
                              {{ item.permissionStatus }}
                              <v-icon
                                small
                                style="cursor: pointer"
                                title="View Reason"
                                >mdi-file-document-outline</v-icon
                              >
                            </span>
                            <span v-else-if="item.permissionStatus == ''">-</span>
                            <span v-else>{{ item.permissionStatus }}</span>
                          </td>
                          <!---------------------------------------------------------------------->
                          <td>
                            <div v-if="item.permissionStatus == 'Pending'">
                              <span>
                                <v-icon
                                  color="green"
                                  title="Approve Request"
                                  @click="
                                    (approvedialoge = true), (curId = item._id)
                                  "
                                >
                                  mdi-check-all
                                </v-icon>
                              </span>
                              <span
                                ><v-icon
                                  color="red"
                                  title="Reject Request"
                                  @click="
                                    (rejectdialoge = true), (curId = item._id)
                                  "
                                >
                                  mdi-close
                                </v-icon></span
                              >
                            </div>
                            <div v-else><span>-</span></div>
                          </td>
                          <!---------------------------------------------------------------------->
                          <!-- <td>
                            <div v-if="item.permissionStatus == 'Pending'">
                              <v-btn outlined small @click="approveData(item._id)">
                                Approve
                              </v-btn>
                            </div>
                            <div v-else>
                              <v-btn small disabled text outlined plain>
                                Approved
                              </v-btn>
                            </div>
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
      <v-dialog width="400px" v-model="approvedialoge">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Confirm </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text"
                >Are you sure you want to Approve?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="approvedialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="approveData()"
                  >Approve</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog width="400px" v-model="rejectdialoge">
            <v-card width="400px">
              <v-toolbar dark color="#766BC0" dense flat class="body-2">
                <v-toolbar-title> Confirm </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text"
                >Enter reject reason
              </v-card-text>
              <v-flex xs12 px-4 py-2>
                  <v-text-field
                    v-model="reson"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>

              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="rejectdialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="rejectData()"
                  >Reject</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      fromDate:null,
      toDate:null,
      // fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      // toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      keyword: null,
      msg: null,
      reson: null,
      //    issueStatus : ["All", "Out", "Issued", "In"],
      //   cvalue: "All",
      approvedialoge: false,
      rejectdialoge: false,
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      outpass: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.getData();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
    // cvalue() {
    //   if(this.page>1)
    //   this.page=1
    //   this.getData();
    // },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/permission/pending/getalllist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          //   issueStatus : a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.outpass = response.data.data;

            //    for(let i=0; i<this.outpass.length; i++)
            //    {
            //      if(this.outpass[i].issueStatus == "Active")
            //      {
            //         this.outpass[i].issueStatus = "Issued";
            //      }
            //    }

            this.Pagelength = Math.ceil(response.data.totallength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
       approveData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/studypermission/approvedby/warden",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
         id: this.curId,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
                      this.approvedialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.outpass = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }else {
            this.msg = response.data.msg;
                      this.approvedialoge = false;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
     rejectData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/permission/rejectby/warden",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
         id: this.curId,
         rejectReason: this.reson,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
                      this.rejectdialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.outpass = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
